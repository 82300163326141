@mixin variables {
  :root {
    --gray: #4a4a49;
    --lightCyan: #ecf9ed;
    --lightCyan2: #ace2b2;
    --darkCyan: #005748;
    --darkCyan2: #1d1d1d;
    --cyan: #46a270;
    --cyan2: #30844a;
    --pink: #f28699;
    --lilac: #9581cb;
    --yellow: #ffd731;
    --mostlyWhite: #f0f0f0;
    --lightGray: #efe7de;
    --lightGray2: #f4ede1;
    --lightGray3: #e3e5da;
    --lightGray4: #f8f6f4;
    --red: #e5657c;
    --red2: #bd0000;
    --red3: #e9634e;
    --white: #ffffff;
    --purple: #6883ff;
    --welcomeImage: url("/assets/ecommerce-images/welcomeImage.jpg");
    --cardBlockRadius: 8px;
    --cardBlockRadius2: 16px;
    --cardBlockRadius3: 4px;
    --inputHeight: 46px;
    --dialogBackground: rgba(0, 0, 0, 0.3);
    --mdc-checkbox-state-layer-size: 40px;
  }
}
