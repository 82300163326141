@use "variables";
@use "keyframes";

@mixin common($needDragPlaceholder: true, $inputBtn: false) {
  @include variables.variables;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    line-height: 1.2;
    margin: 0;
    overflow: hidden;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    text-align: left !important;
    -webkit-text-size-adjust: 100%;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    background: transparent;
    height: 5px;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(125, 125, 125, 0.5);
    border: none;
    -webkit-border-radius: var(--cardBlockRadius);
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  :focus {
    outline: none;
  }

  button {
    -webkit-appearance: button;
    background-color: transparent;

    &:focus {
      outline: none !important;
    }
  }

  [role="button"] {
    cursor: pointer;
  }

  .cursor-disabled,
  button[disabled] {
    cursor: not-allowed !important;
    pointer-events: auto !important;
  }

  button[disabled][disabled],
  .disabled {
    opacity: 0.5;
  }

  ol,
  ul {
    list-style: none;
    margin-bottom: 1rem;
    margin-top: 0;
    padding: 0;

    li {
      span {
        vertical-align: text-top;
      }
    }
  }

  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }

  blockquote {
    margin: 0 0 1rem;
  }

  b,
  strong {
    font-family: OpenSansBold, sans-serif !important;
  }

  span,
  label {
    word-break: break-word;
  }

  label {
    display: inline-block;
  }

  img {
    pointer-events: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    margin-top: 0;
  }

  input {
    caret-color: var(--gray);
    color: var(--gray) !important;
    height: var(--inputHeight) !important;
    margin-top: 0 !important;

    &:hover,
    &:focus {
      box-shadow: none;
      outline: none !important;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px var(--lightGray3) inset !important;
    -webkit-text-fill-color: var(--gray) !important;
  }

  .checkbox-start-0 {
    .mdc-checkbox {
      padding-left: 0;

      .mdc-checkbox__background {
        left: 0 !important;
      }
    }
  }

  .mat-mdc-select-disabled,
  input:disabled,
  input[readonly] {
    background-color: var(--mostlyWhite) !important;
    cursor: not-allowed !important;
  }

  .mat-mdc-select-placeholder {
    font-size: 13px;
  }

  .mat-form-field-disabled,
  .mat-mdc-form-field:has(input:disabled),
  .mat-mdc-form-field:has(input[readonly]) {
    pointer-events: none;

    .mat-mdc-select-value {
      padding-right: 3.5rem !important;
    }

    .mat-mdc-input-element {
      border-radius: var(--cardBlockRadius) !important;
    }

    .mat-mdc-select-arrow-wrapper {
      right: 3rem !important;
    }

    &:not(.removeArrows) .mat-mdc-form-field-infix {
      &:before {
        bottom: 10px;
        color: var(--gray);
        content: url("/assets/images/disabled.svg");
        height: 25px;
        position: absolute;
        right: 0.7rem;
        width: 25px;

        path {
          stroke-width: 1.5px;
        }
      }
    }

    &:not(.removeArrows) .mat-mdc-form-field-icon-suffix,
    &:not(.removeArrows) .mat-mdc-form-field-icon-prefix {
      background-color: var(--mostlyWhite) !important;

      .mat-mdc-icon-button,
      .mat-icon-button {
        display: none !important;
      }
    }
  }

  .mat-mdc-select-disabled {
    .mat-mdc-select-trigger {
      cursor: not-allowed !important;
    }
  }

  a {
    text-decoration: none !important;
  }

  p {
    margin-bottom: 1rem;
    margin-top: 0;
  }

  .container {
    position: relative;
  }

  .row {
    flex: 1 1 auto;
    margin: 0;

    > * {
      max-width: unset;

      &.row {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .nav {
    display: flex;
    flex-wrap: wrap !important;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
  }

  hr {
    background-color: var(--mostlyWhite);
    border: none;
    display: block;
    height: 1px;
    margin: 1.8rem auto !important;
  }

  @include keyframes.keyframes($name: fadeIn) {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .mat-mdc-form-field-icon-prefix,
  .mat-mdc-form-field-icon-suffix {
    align-self: flex-end !important;
    flex-shrink: 0;
    padding: 0 !important;

    .mat-mdc-icon-button,
    .mat-icon-button {
      border-radius: inherit;
      display: block;
      height: var(--inputHeight);
      line-height: var(--inputHeight) !important;
      position: relative;
    }
  }

  .snack-Error {
    .mdc-snackbar__surface {
      background-color: var(--red2) !important;
    }
  }

  .snack-Success {
    .mdc-snackbar__surface {
      background-color: var(--cyan) !important;
    }
  }

  .mat-mdc-snack-bar-container {
    color: var(--white);
    margin-bottom: 1rem !important;
    min-width: 420px !important;
    position: relative;

    .mdc-snackbar__surface {
      border-radius: var(--cardBlockRadius) !important;
      min-width: 100% !important;
    }
    @media screen and (max-width: 768px) {
      min-width: calc(100% - 2rem) !important;
    }
  }

  .remove {
    background-color: var(--red2) !important;
  }

  .add,
  .saveBtn {
    background-color: var(--cyan) !important;
  }

  .saveBtn {
    transition: background-color 0.2s ease;

    &:hover {
      background-color: var(--cyan2) !important;
    }
  }

  .option,
  .blueButton {
    background-color: var(--darkCyan) !important;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: var(--darkCyan2) !important;
    }
  }

  .yellowButton {
    background-color: var(--yellow) !important;

    .mdc-button__label {
      color: var(--darkCyan) !important;
    }
  }

  .cancelBtn {
    background-color: var(--red2) !important;
    transition: opacity 0.2s ease;

    &:not(:disabled):hover {
      opacity: 0.75 !important;
    }

    span {
      color: var(--white) !important;
    }
  }

  .selectBtn {
    justify-content: left !important;
    width: 100%;
  }

  .mat-mdc-form-field-hint,
  .mat-mdc-form-field-error {
    font-family: OpenSans, sans-serif;
    font-size: 11px;
    margin-top: 0;
    white-space: normal !important;
  }

  .mat-mdc-form-field-error {
    color: var(--red) !important;
    text-align: center;
  }

  .mat-mdc-form-field {
    font-family: OpenSans, sans-serif;
    position: relative;

    &:not([class*="col-"]) {
      width: 100%;
    }

    .mat-mdc-text-field-wrapper {
      padding: 0;
    }

    .mat-mdc-form-field-subscript-wrapper {
      min-height: 1rem;
    }

    .mdc-text-field--outlined .mdc-text-field__input {
      background-color: var(--mostlyWhite);
      border: none;
    }

    .mat-input-value,
    .mat-mdc-input-element {
      background-color: var(--mostlyWhite);
      border: none;
      border-radius: var(--cardBlockRadius);
      color: var(--gray) !important;
      font-family: OpenSans, sans-serif !important;
    }

    .mat-form-field-placeholder {
      color: var(--gray) !important;
      font-size: 13px;
      padding: 0.4375em;
    }
  }

  .differenceForm {
    .ng-dirty.mat-mdc-form-field.mat-form-field-disabled {
      label {
        color: var(--yellow) !important;
      }

      .mat-mdc-select-placeholder {
        color: var(--white) !important;
      }

      .mat-mdc-form-field-infix {
        .mat-mdc-input-element {
          background-color: var(--yellow) !important;
          caret-color: var(--white);
          color: var(--white) !important;
        }

        ::placeholder {
          color: var(--white) !important;
          opacity: 1;
        }
      }

      &:not(.removeArrows) .mat-mdc-form-field-icon-suffix {
        background-color: var(--yellow) !important;
        color: var(--white) !important;
      }

      .mat-empty {
        -webkit-text-fill-color: transparent;
        text-shadow: 0 0 0 var(--white);
      }

      .mat-mdc-select {
        border-color: var(--yellow) !important;

        .mat-mdc-select-trigger {
          background-color: var(--yellow) !important;
          border-radius: var(--cardBlockRadius);
          color: var(--white) !important;
        }

        .mat-mdc-select-arrow-wrapper {
          .mat-mdc-select-arrow {
            background-color: var(--white) !important;
          }
        }
      }

      .mat-mdc-icon-button,
      .mat-icon-button {
        color: var(--white) !important;
      }

      .mat-mdc-select-value {
        span {
          color: var(--white) !important;
        }
      }
    }
  }

  .mat-mdc-form-field-infix {
    border: none;
    padding-bottom: 0 !important;
    padding-top: 0 !important;

    .mat-mdc-input-element {
      box-sizing: border-box;
      padding: 0.6375em !important;
    }
  }

  .mdc-notched-outline {
    display: none !important;
  }

  textarea {
    min-height: 70px;
    padding: 0.6375em !important;
    resize: vertical;
  }

  [hidden] {
    display: none !important;
  }

  ::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::placeholder {
    font-size: 13px;
  }

  .mat-form-field-invalid {
    label {
      color: var(--red) !important;
    }

    .mat-mdc-form-field-infix {
      .mat-mdc-input-element {
        background-color: var(--red) !important;
        caret-color: var(--white);
        color: var(--white) !important;
      }

      ::placeholder {
        color: var(--white) !important;
        opacity: 1;
      }
    }

    &:not(.errorException) .mat-mdc-form-field-icon-suffix,
    &:not(.errorException) .mat-mdc-form-field-icon-prefix {
      background-color: var(--red) !important;
      color: var(--white) !important;

      .mat-icon-button,
      .heroIcon {
        color: var(--white) !important;
      }
    }

    &:not(.errorException) .mat-mdc-form-field-icon-prefix {
      border-right: 2px solid var(--mostlyWhite) !important;
    }

    &:not(.errorException) .mat-mdc-form-field-icon-suffix {
      border-left: 2px solid var(--mostlyWhite) !important;
    }

    .mat-empty {
      -webkit-text-fill-color: transparent;
      text-shadow: 0 0 0 var(--white);
    }

    .mat-mdc-select {
      border-color: var(--red) !important;

      .mat-mdc-select-trigger {
        background-color: var(--red) !important;
        border-radius: var(--cardBlockRadius);
        color: var(--white) !important;
      }

      .mat-mdc-select-arrow-wrapper {
        .mat-mdc-select-arrow {
          background-color: var(--white) !important;
        }
      }
    }

    .mat-mdc-select-value {
      span {
        color: var(--white) !important;
      }
    }
  }

  .mat-datepicker-content {
    font-family: OpenSans, sans-serif;

    .mat-calendar-body-selected {
      background-color: var(--cyan2);
      color: var(--white);
    }

    .mat-calendar-period-button {
      font-family: OpenSans, sans-serif;
    }
  }

  .mat-mdc-select {
    background-color: var(--mostlyWhite);
    border: none;
    border-radius: var(--cardBlockRadius);
    display: block !important;
    font-family: OpenSans, sans-serif;
    height: var(--inputHeight);
    width: 100%;

    .mat-mdc-select-trigger {
      background-color: transparent;
      display: block;
      height: var(--inputHeight);
      padding: 0.6375em !important;
    }

    .mat-mdc-select-value {
      color: var(--gray);
      font-family: OpenSans, sans-serif;
      line-height: 30px !important;
      padding-right: 1.5rem;
    }

    .mat-mdc-select-arrow-wrapper {
      bottom: 0.7rem;
      position: absolute;
      right: 0.9rem;

      .mat-mdc-select-arrow {
        background-color: var(--darkCyan2);
        height: 20px;
        mask: url("/assets/images/arrowDown.svg") no-repeat 50% 50%;
        mask-size: cover;
        width: 20px;

        svg {
          display: none;
        }
      }
    }
  }

  .mat-mdc-select-panel {
    background-color: var(--white);
    box-shadow:
      0 2px 4px -1px rgb(0 0 0 / 20%),
      0 4px 5px 0 rgb(0 0 0 / 14%),
      0 1px 10px 0 rgb(0 0 0 / 12%) !important;

    .mat-mdc-option {
      font-family: OpenSans, sans-serif;
      min-height: 3em;
    }

    @media screen and (max-width: 768px) {
      left: 100px;
      position: absolute;
      width: calc(100% - 94px);
    }
    @media screen and (max-width: 576px) {
      left: 30px;
      width: calc(100% - 1rem);
    }
  }

  .mat-mdc-select-panel,
  .mat-mdc-autocomplete-panel {
    background-color: var(--white) !important;

    .mat-mdc-option-active,
    .mdc-list-item--selected {
      background-color: var(--lightGray) !important;

      .mdc-list-item__primary-text {
        color: var(--gray);
      }
    }
  }

  .mat-pseudo-checkbox {
    border: 2px solid var(--lightGray2) !important;
  }

  .notLoaded {
    display: none;
  }

  .loadedContent {
    animation: fadeIn 1.8s;
    display: block;
  }

  .loadFlexContent {
    animation: fadeIn 1.8s;
    display: flex;
  }

  .cancelBtn,
  .saveBtn,
  .rejectBtn,
  .grayBtn,
  .removeBtn,
  .yellowButton,
  .blueButton {
    border-radius: var(--cardBlockRadius) !important;
    font-family: OpenSansBold, sans-serif;
    min-width: 170px !important;

    svg {
      color: var(--white);
      height: 25px;
      margin-right: 5px;

      path {
        stroke-width: 1.5px;
      }
    }

    .mat-mdc-progress-spinner {
      margin: 0.5rem 0;

      .mdc-circular-progress__circle-clipper {
        height: 100%;
        width: 100%;

        svg {
          height: 100%;
          width: 100%;
        }
      }

      .mdc-circular-progress__gap-patch {
        display: none;
      }
    }

    .mdc-button__label {
      color: var(--white);
      font-size: 12px;
    }
  }

  .saveBtn,
  .yellowButton,
  .cancelBtn,
  .rejectBtn,
  .btnRow,
  .blueButton,
  .sendingBtn,
  .fileUpload,
  .imageViewer {
    .mat-mdc-progress-spinner {
      circle {
        stroke: var(--white) !important;
      }
    }
  }

  .mdc-button,
  .mat-mdc-button {
    display: inline-block;
    height: auto !important;
    line-height: 36px !important;
    white-space: normal !important;

    &:not([disabled]) {
      .mdc-button__label label {
        cursor: pointer;
      }
    }

    &[disabled] {
      .mdc-button__label label {
        cursor: not-allowed;
      }
    }
  }

  @if ($needDragPlaceholder) {
    .dragPlaceholder {
      background: var(--lightGray4);
      border: dotted 3px var(--darkCyan2);
      min-height: 60px;
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }
  }

  .mat-mdc-dialog-container {
    border-radius: var(--cardBlockRadius) !important;
    overflow: hidden !important;
    padding: 0 !important;

    .mdc-dialog__surface {
      background-color: var(--white) !important;
      box-shadow: none !important;
    }
  }

  .mat-mdc-progress-spinner {
    circle {
      stroke: var(--cyan) !important;
    }
  }

  .cdk-overlay-container {
    z-index: 2000;
  }

  .cdk-overlay-pane:not(.customMenu) {
    max-width: 100% !important;
    @media screen and (max-width: 768px) {
      width: calc(100% - 2rem) !important;

      .mat-mdc-dialog-container {
        max-width: 100% !important;
      }
    }

    @media screen and (max-width: 576px) {
      .mat-mdc-dialog-container {
        max-height: calc(var(--vh, 1vh) * 100 - 30px);
        overflow-y: auto !important;
      }
    }
  }

  .withoutMarginField {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  .img-fluid {
    height: auto;
    max-width: 100%;
  }

  .cdk-overlay-dark-backdrop {
    background-color: var(--dialogBackground) !important;
  }

  .rapidScrollbar {
    .ng-scroll-content {
      width: 100%;
    }
  }

  .h-100 {
    height: 100% !important;
  }

  .mat-mdc-autocomplete-panel {
    .mat-mdc-option {
      font-family: OpenSans, sans-serif;
      line-height: 3em;
      min-height: 3em;
    }
  }

  .mat-mdc-tooltip {
    background-color: var(--gray);
    border-radius: var(--cardBlockRadius3);
    margin: 0 14px;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;

    .mdc-tooltip__surface {
      color: var(--white) !important;
      font-family: OpenSans, sans-serif !important;
      padding: 6px 8px;
      text-align: center !important;
    }
  }

  .highZ {
    z-index: 9999999999;
  }
  @if ($inputBtn) {
    .inputBtn {
      min-height: 33px !important;
      min-width: 100px !important;
    }
  }

  input[type="search" i]::-webkit-search-cancel-button {
    display: none !important;
  }
}
