@use "./material";
@use "./bootstrap";
@use "shopiagoDesign" as shopiago;

@include material.material;
@include bootstrap.bootstrap;
@include shopiago.font-face($selector: OpenSans, $name: OpenSans);
@include shopiago.font-face($selector: OpenSansBold, $name: OpenSansBold);
@include shopiago.font-face($selector: OpenSansBold, $name: OpenSansBold);
@include shopiago.common;

blockquote {
  border-left: 5px solid var(--yellow);
  padding: 0.5rem 0 0.5rem 0.3rem;
}

.method {
  border-radius: 2px;
  color: var(--white);
  display: inline-block;
  font-family: OpenSansBold, sans-serif;
  font-size: 10px;
  margin-right: 5px;
  padding: 2px 5px;
  text-align: center;
  text-transform: uppercase;
  width: 55px;
}

.get {
  background-color: var(--purple);
  border: 1px solid var(--purple);
}

.post {
  background-color: var(--cyan);
  border: 1px solid var(--cyan);
}

.put {
  background-color: var(--cyan);
  border: 1px solid var(--cyan);
}

.delete {
  background-color: var(--red2);
  border: 1px solid var(--red2);
}
